import { BrowserTracing, captureException, getCurrentHub, init, showReportDialog } from "@sentry/browser"
import { useEffect } from "react"
import { secrets } from "secrets"

export function useInitSentry() {
  const sentryEnvironment =
    typeof window !== "undefined"
      ? window.location.origin === secrets.settings.appUrl
        ? "production"
        : "development"
      : undefined

  const getpackedVersion = "getpacked-app@" + (secrets.settings.version || "undefined")

  useEffect(() => {
    if (sentryEnvironment && secrets.sentry) {
      if (getCurrentHub().getClient() !== undefined) {
        captureException("Sentry already initialized")
        return
      }
      init({
        dsn: secrets.sentry.appDsn,
        environment: sentryEnvironment,
        // To set your release version
        release: getpackedVersion,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5,

        beforeSend: (event, hint) => {
          const error = hint?.originalException
          if (error) {
            event.extra = {
              ...(event.extra ? event.extra : {}),
              exception: error,
              message:
                typeof error === "object"
                  ? ((error as { message?: string }).message ?? "no error message")
                  : "" + ((error as string) || "no error message"),
            }
          }
          const openFeedbackDialog = () =>
            showReportDialog({
              eventId: event.event_id,
              user: {
                email: "bugs@weborder.gastromia.de",
              },
              lang: "de",
              title: "😕 Ein Fehler ist aufgetreten",
              subtitle:
                "Es würde uns sehr weiterhelfen, wenn du kurz beschreibst was zuletzt passiert oder schiefgelaufen ist.",
              subtitle2: "Wenn du deine E-Mail angibst, melden wir uns sobald der Fehler behoben ist.",
              labelName: "Name",
              labelEmail: "E-Mail (optional)",
              errorGeneric: "Da ist etwas schiefgelaufen.",
              errorFormEntry:
                "Bitte überprüfe nochmal deine Eingaben. Wenn du keinen Namen oder keine E-Mail angeben willst, füll die Felder einfach mit falschen Daten aus. (z.B. Max und bugs@weborder.gastromia.de )",
              successMessage: "Danke für deine Rückmeldung.",
            })

          if (error) {
            const errorMessage =
              typeof error === "object"
                ? ((error as { message?: string }).message ?? "no error message")
                : "" + ((error as string) || "no error message")
            // If the page is in background for too long, safari does some weird stuff and breaks some stuff.
            // This causes these errors on reentry. We do a full reload to restore a valid state.
            if (
              // https://sentry.io/organizations/getpacked/issues/2750343007
              // The most common background error
              errorMessage.includes("Load failed") ||
              // https://sentry.io/organizations/getpacked/issues/2325645981
              // Usually also indicates that something went wrong, where reloading may help
              errorMessage.includes("Connection to Indexed Database server lost") ||
              // https://sentry.io/organizations/getpacked/issues/2709363458
              // These should also be background errors
              errorMessage.includes("Die Netzwerkverbindung wurde unterbrochen") ||
              errorMessage.includes("La connexion réseau a été perdue") ||
              errorMessage.includes("The network connection was lost")
            ) {
              // Reload in a second, so the event can still be sent before
              setTimeout(() => {
                //TODO: Find a way to only refresh firestore listeners
                typeof window !== "undefined" && window.location.reload()
              }, 1500)

              // // Prevent idb error to occur really often
              // if(errorMessage.includes("Connection to Indexed Database server lost. Refresh the page to try again")){
              //   if (idbErrorOcurred.current) {
              //     return null
              //   }
              //   idbErrorOcurred.current = true
              // }

              // event.breadcrumbs?.push({
              //   type: "info",
              //   category: "orders",
              //   message: `Reloading page`,
              //   level: Severity.Info,
              //   timestamp: getDate().getTime(),
              // })
              return null
            }

            if (
              errorMessage === "internal" ||
              errorMessage === "Test sentry" ||
              errorMessage ===
                "Der Vorgang konnte nicht abgeschlossen werden. Die Software hat einen Verbindungsabbruch verursacht" ||
              errorMessage === "INTERNAL ASSERTION FAILED: Pending promise was never set" ||
              errorMessage.includes("undefined is not an object") ||
              errorMessage.includes("A user wants to report an error")
            ) {
              setTimeout(() => {
                openFeedbackDialog()
              }, 1500)
              return event
            }
          }

          return event
        },
        ignoreErrors: [
          // Common errors, that are not our fault from https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
          // Random plugins/extensions
          "top.GLOBALS",
          // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
          "originalCreateNotification",
          "canvas.contentDocument",
          "MyApp_RemoveAllHighlights",
          "http://tt.epicplay.com",
          "Can't find variable: ZiteReader",
          "jigsaw is not defined",
          "ComboSearch is not defined",
          "http://loading.retry.widdit.com/",
          "atomicFindClose",
          // Facebook borked
          "fb_xd_fragment",
          // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
          // reduce this. (thanks @acdha)
          // See http://stackoverflow.com/questions/4113268
          "bmi_SafeAddOnload",
          "EBCallBackMessageReceived",
          // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
          "conduitPage",

          // This is not a fixable bug. Afaik it does not cause issues.
          "ResizeObserver loop limit exceeded",
          // https://sentry.io/organizations/getpacked/issues/2957803741
          // This happens sometimes on iOS devices. Afaik does not cause a crash just no audio.
          "Failed to start the audio device",
          // https://sentry.io/organizations/getpacked/issues/2492623795
          // Probably no crash
          "Error looking up record in object store by key range",
          // https://sentry.io/organizations/getpacked/issues/2956720131
          // Probably just some missed resizes
          "ResizeObserver loop completed with undelivered notifications",
        ],
        denyUrls: [
          // Common errors, that are not our fault from https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
          // Facebook flakiness
          /graph\.facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Woopra flakiness
          /eatdifferent\.com\.woopra-ns\.com/i,
          /static\.woopra\.com\/js\/woopra\.js/i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Other plugins
          /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
          /webappstoolbarba\.texthelp\.com\//i,
          /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
      })
    }
  }, [secrets.sentry, !!sentryEnvironment])
}
