import { CustomTheme, UiColor } from "types/firestore/shop"

interface ThemeProps {
  uiColor?: UiColor
  customShopTheme?: CustomTheme
}

export const themes: Partial<Record<UiColor, { primary: string; secondary: string; gradientText: string }>> = {
  green: { primary: "#099934", secondary: "#7bc14d", gradientText: "white" },
  red: { primary: "#960814", secondary: "#c90a1b", gradientText: "white" },
  olive: { primary: "#5d772b", secondary: "#8a9c5b", gradientText: "white" },
  blue: { primary: "#1386bf", secondary: "#0e548c", gradientText: "white" },
  yellow: { primary: "#ffdf00", secondary: "#bda820", gradientText: "black" },
  purple: { primary: "#735fd9", secondary: "#442f94", gradientText: "white" },
  gray: { primary: "#222", secondary: "#000", gradientText: "white" },
  baby: { primary: "#d1caed", secondary: "#0382dd", gradientText: "white" },
  ice: { primary: "#c4d4f2", secondary: "#5c6373", gradientText: "white" },
  dream: { primary: "#52c9dd", secondary: "#5097b1", gradientText: "white" },
  dark_dream: { primary: "#466982", secondary: "#343f53", gradientText: "white" },
  blue_night: { primary: "#468ba6", secondary: "#0d0d0d", gradientText: "white" },
  spring: { primary: "#1ad5a0", secondary: "#33f330", gradientText: "white" },
  spring_two_color: { primary: "#0cc5d7", secondary: "#33f330", gradientText: "white" },
  forest: { primary: "#7aa555", secondary: "#165955", gradientText: "white" },
  lemon: { primary: "#8ac66d", secondary: "#c6e66c", gradientText: "black" },
  dark_lemon: { primary: "#59a46a", secondary: "#195f54", gradientText: "white" },
  orange: { primary: "#f2b66d", secondary: "#bf5517", gradientText: "white" },
  bakery: { primary: "#f2d091", secondary: "#a6381f", gradientText: "white" },
  coffee: { primary: "#d99f6c", secondary: "#401201", gradientText: "white" },
  creme: { primary: "#f2ddd5", secondary: "#8c5845", gradientText: "black" },
  purpur: { primary: "#f2d6a2", secondary: "#f280b6", gradientText: "black" },
  rosa: { primary: "#f2ced5", secondary: "#d96c80", gradientText: "white" },
  pink: { primary: "#f280b6", secondary: "#bf3459", gradientText: "white" },
  pink_blue: { primary: "#f22e9a", secondary: "#262a73", gradientText: "white" },
  night: { primary: "#733663", secondary: "#0d0d0d", gradientText: "white" },
  coral: { primary: "#9e392b", secondary: "#6b1c33", gradientText: "white" },
  red_1: { primary: "#d95b66", secondary: "#73020c", gradientText: "white" },
  fire: { primary: "#ff4c00", secondary: "#802600", gradientText: "white" },
  fire_2: { primary: "#ffca50", secondary: "#9e332e", gradientText: "white" },
  red_black: { primary: "#f20c36", secondary: "#222126", gradientText: "white" },
  christmas: { primary: "#bf1736", secondary: "#8c0d24", gradientText: "white" },
  dark_red: { primary: "#8c0303", secondary: "#260101", gradientText: "white" },
}

const Theme = ({ uiColor, customShopTheme }: ThemeProps) => {
  const theme =
    uiColor &&
    (uiColor === "custom" && customShopTheme
      ? {
          primary: customShopTheme.startColor,
          secondary: customShopTheme.useGradient ? customShopTheme.endColor : customShopTheme.startColor,
          gradientText: customShopTheme.darkText ? "black" : "white",
        }
      : themes[uiColor])

  return theme ? (
    <style jsx global>{`
      :root:root {
        --primary: ${theme.primary};
        --secondary: ${theme.secondary};
        --gradientText: ${theme.gradientText};
      }
    `}</style>
  ) : null
}

export default Theme
