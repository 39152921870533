import { getApps } from "firebase/app"
import { connectAuthEmulator, getAuth } from "firebase/auth"
import firebase from "firebase/compat/app"
import { connectFirestoreEmulator, getFirestore, initializeFirestore } from "firebase/firestore"
import { connectFunctionsEmulator, getFunctions } from "firebase/functions"
import { connectStorageEmulator, getStorage } from "firebase/storage"
import "fix-date"
import { useInitSentry } from "hooks/misc/useInitSentry"

import { secrets } from "secrets"

if (!firebase.apps.length) {
  try {
    // initializeApp(secrets.firebase)
    firebase.initializeApp(secrets.firebase)

    // Longpolling may fix https://sentry.io/organizations/getpacked/issues/2750343007/?project=5185239
    const firestoreSettings = { ignoreUndefinedProperties: true, experimentalForceLongPolling: true }
    initializeFirestore(getApps()[0], firestoreSettings)
  } catch (error) {
    console.log(error)
  }

  if (secrets.emulator?.host && process.env.NEXT_PUBLIC_USE_FIRESTORE_EMULATOR === "true") {
    if (secrets.emulator.firestorePort) {
      connectFirestoreEmulator(getFirestore(), secrets.emulator.host, secrets.emulator.firestorePort)
    }
    if (secrets.emulator.authPort) {
      connectAuthEmulator(getAuth(), "http://" + secrets.emulator.host + ":" + secrets.emulator.authPort)
    }
    if (secrets.emulator.functionsPort) {
      connectFunctionsEmulator(getFunctions(), secrets.emulator.host, secrets.emulator.functionsPort)

      connectFunctionsEmulator(
        getFunctions(getApps()[0], "europe-west1"),
        secrets.emulator.host,
        secrets.emulator.functionsPort
      )
      connectFunctionsEmulator(
        getFunctions(getApps()[0], "europe-west3"),
        secrets.emulator.host,
        secrets.emulator.functionsPort
      )
    }
    if (secrets.emulator.storagePort) {
      connectStorageEmulator(getStorage(), secrets.emulator.host, secrets.emulator.storagePort)
    }
  }
}

export function useInitialize() {
  useInitSentry()
}

export const localAlgoliaIndexPre = secrets.algolia?.indexPre

export const localAlgoliaVars = { appId: secrets.algolia?.appId }
