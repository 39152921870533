import Head from "next/head"

interface CommonHeadProps {
  title?: string
  description?: string
  image?: string
  url?: string
  hide?: boolean
  shopImage?: string
}

const CommonHead = ({
  title = "gastromia",
  description = "Lokale Produkte - einfach bestellen.",
  image = "/android-icon-256x256.png",
  url,
  hide,
  shopImage,
}: CommonHeadProps) => {
  return (
    <Head>
      <meta key="charSet" charSet="utf-8" />

      <title>{title}</title>
      <meta key="og:title" property="og:title" content={title} />

      <meta key="description" name="description" content={description} />
      <meta key="og:description" property="og:description" content={description} />

      <meta key="og:image" property="og:image" content={shopImage || image} />
      <meta key="twitter:card" property="twitter:card" content={shopImage || image} />

      {url ? <meta key="og:url" property="og:url" content={url} /> : null}

      <meta key="msapplication-TileColor" name="msapplication-TileColor" content="#da532c" />
      <meta key="msapplication-TileImage" name="msapplication-TileImage" content={shopImage || "/mstile-150x150.png"} />
      <meta key="theme-color" name="theme-color" content="#ffffff" />

      <link key="icon1" rel="icon" type="image/ico" href={shopImage || "/favicon.ico"} />
      <link key="icon2" rel="shortcut icon" href={shopImage || "/favicon.ico"} />
      <link key="icon3" rel="apple-touch-icon" href={shopImage || "/apple-touch-icon.png"} />
      <link key="icon4" rel="icon" type="image/png" sizes="256x256" href={shopImage || "/android-icon-256x256.png"} />
      <link key="icon5" rel="icon" type="image/png" sizes="192x192" href={shopImage || "/android-icon-192x192.png"} />

      {hide ? <meta key="robots" name="robots" content="noindex,follow" /> : null}
    </Head>
  )
}

export default CommonHead
