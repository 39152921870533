import publicSecrets from "./publicSecrets.json"

interface Firebase {
  apiKey: string
  authDomain: string
  databaseURL?: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

interface Google {
  placesKey: string
  embeddedMapsKey: string
}

interface Emulator {
  host: string
  firestorePort?: number
  authPort?: number
  functionsPort?: number
  storagePort?: number
}

interface Algolia {
  appId: string
  indexPre: string
}

interface Stripe {
  clientId: string
  key: string
}

interface Settings {
  appUrl: string
  functionsUrl: string
  euFunctionsUrl: string
  version: string
}

interface Sentry {
  appDsn: string
}

interface PrivateServiceAccount {
  type: string
  project_id: string
  private_key: string
  private_key_id: string
  client_email: string
  client_id: string
  auth_uri: string
  token_uri: string
  auth_provider_x509_cert_url: string
  client_x509_cert_url: string
}

interface PrivateSentry extends Sentry {
  functionsDsn: string
}

interface PrivateAlgolia extends Algolia {
  adminKey: string
}

interface PrivateSendgrid {
  apiKey: string
}

interface PrivateFirebase extends Firebase {
  backupBucket: string
}

interface PrivateStripe extends Stripe {
  secret: string
  creditPurchaseWebhookSecret: string
  paymentMethodChangeWebhookSecret: string
  invoicePaymentWebhookSecret: string
  successfulPurchaseWebhookSecret: string
  taxRateKey: string
  subscriptionPriceKey: string
  subscriptionItemPriceKey: string
}

interface Cookiebot {
  id: string
}

interface Flatfile {
  publishableKey: string
  environmentId: string
}

type Env = string

export interface PublicSecrets {
  firebase: Firebase
  stripe: Stripe
  stripeSandbox: Stripe
  algolia: Algolia
  sentry?: Sentry
  settings: Settings
  emulator?: Emulator
  google: Google
  cookiebot: Cookiebot
  flatfile: Flatfile
  env: Env
}

export interface PrivateSecrets {
  algolia: PrivateAlgolia
  serviceAccount: PrivateServiceAccount
  sendgrid: PrivateSendgrid
  firebase: PrivateFirebase
  stripe: PrivateStripe
  stripeSandbox: PrivateStripe
  sentry?: PrivateSentry
  settings: Settings
  emulator?: Emulator
  google: Google
  cookiebot: Cookiebot
}

// export const secrets: PublicSecrets = process.env.NEXT_PUBLIC_SECRETS
//   ? require(process.env.NEXT_PUBLIC_SECRETS || "./publicSecrets.json")
//   : publicSecrets
export const secrets: PublicSecrets = publicSecrets
